export const stadiums = {
  MLB: {
    LAA: {
      team: "Los Angeles Angels",
      stadium: "Angel Stadium",
      stadiumID: 13,
      address: "2000 Gene Autry Way, Anaheim, CA 92806",
      phone: "(714) 940-2000",
      website: "angels.com",
      orientation: 70,
      teamId: 108,
      abbreviation: "LAA",
      briefName: "Angels",
    },
    ARI: {
      team: "Arizona Diamondbacks",
      stadium: "Chase Field",
      stadiumID: 7,
      address: "401 East Jefferson Street, Phoenix, AZ 85004",
      phone: "(602) 462-6500",
      website: "dbacks.com",
      orientation: 45,
      teamId: 109,
      abbreviation: "ARI",
      briefName: "D-backs",
    },
    BAL: {
      team: "Baltimore Orioles",
      stadium: "Oriole Park",
      stadiumID: 3,
      address: "333 West Camden Street, Baltimore, MD 21201",
      phone: "(410) 685-9800",
      website: "orioles.com",
      orientation: 65,
      teamId: 110,
      abbreviation: "BAL",
      briefName: "Orioles",
    },
    BOS: {
      team: "Boston Red Sox",
      stadium: "Fenway Park",
      stadiumID: 4,
      address: "4 Jersey Street, Boston, MA 02215",
      phone: "(617) 267-9440",
      website: "redsox.com",
      orientation: 20,
      teamId: 111,
      abbreviation: "BOS",
      briefName: "Red Sox",
    },
    CHC: {
      team: "Chicago Cubs",
      stadium: "Wrigley Field",
      stadiumID: 5,
      address: "1060 West Addison, Chicago, IL 60613-4397",
      phone: "(773) 404-2827",
      website: "cubs.com",
      orientation: 105,
      teamId: 112,
      abbreviation: "CHC",
      briefName: "Cubs",
    },
    CIN: {
      team: "Cincinnati Reds",
      stadium: "Great American Ball Park",
      stadiumID: 1,
      address: "100 Main Street, Cincinnati, OH 45202-4109",
      phone: "(513) 765-7000",
      website: "reds.com",
      orientation: 75,
      teamId: 113,
      abbreviation: "CIN",
      briefName: "Reds",
    },
    CLE: {
      team: "Cleveland Guardians",
      stadium: "Progressive Field",
      stadiumID: 8,
      address: "2401 Ontario Street, Cleveland, OH 44115",
      phone: "(216) 420-4200",
      website: "cleguardians.com",
      orientation: 45,
      teamId: 114,
      abbreviation: "CLE",
      briefName: "Guardians",
    },
    COL: {
      team: "Colorado Rockies",
      stadium: "Coors Field",
      stadiumID: 9,
      address: "2001 Blake Street, Denver, CO 80205-2000",
      phone: "(303) 292-0200",
      website: "rockies.com",
      orientation: 90,
      teamId: 115,
      abbreviation: "COL",
      briefName: "Rockies",
    },
    DET: {
      team: "Detroit Tigers",
      stadium: "Comerica Park",
      stadiumID: 10,
      address: "2100 Woodward Avenue, Detroit, MI 48201",
      phone: "(313) 471-2000",
      website: "tigers.com",
      orientation: 110,
      teamId: 116,
      abbreviation: "DET",
      briefName: "Tigers",
    },
    HOU: {
      team: "Houston Astros",
      stadium: "Minute Maid Park",
      stadiumID: 11,
      address: "501 Crawford Street, Houston, TX 77002",
      phone: "(713) 259-8000",
      website: "astros.com",
      orientation: 30,
      teamId: 117,
      abbreviation: "HOU",
      briefName: "Astros",
    },
    KC: {
      team: "Kansas City Royals",
      stadium: "Kauffman Stadium",
      stadiumID: 12,
      address: "One Royal Way, Kansas City, MO 64129",
      phone: "(816) 921-8000",
      website: "royals.com",
      orientation: 75,
      teamId: 118,
      abbreviation: "KC",
      briefName: "Royals",
    },
    LAD: {
      team: "Los Angeles Dodgers",
      stadium: "Dodger Stadium",
      stadiumID: 14,
      address: "1000 Vin Scully Avenue, Los Angeles, CA 90012",
      phone: "866-DODGERS",
      website: "dodgers.com",
      orientation: 40,
      teamId: 119,
      abbreviation: "LAD",
      briefName: "Dodgers",
    },
    WSH: {
      team: "Washington Nationals",
      stadium: "Nationals Park",
      stadiumID: 30,
      address: "1500 South Capitol Street, SE, Washington, DC 20003-1507",
      phone: "(202) 675-6287",
      website: "nationals.com",
      orientation: 80,
      teamId: 120,
      abbreviation: "WSH",
      briefName: "Nationals",
    },
    NYM: {
      team: "New York Mets",
      stadium: "Citi Field",
      stadiumID: 18,
      address: "41 Seaver Way, Flushing, NY 11368",
      phone: "(718) 507-6387",
      website: "mets.com",
      orientation: 80,
      teamId: 121,
      abbreviation: "NYM",
      briefName: "Mets",
    },
    OAK: {
      team: "Oakland Athletics",
      stadium: "Oakland Coliseum",
      stadiumID: 20,
      address: "7000 Coliseum Way, Oakland, CA 94621",
      phone: "(510) 638-4900",
      website: "athletics.com",
      orientation: 100,
      teamId: 133,
      abbreviation: "OAK",
      briefName: "Athletics",
    },
    PIT: {
      team: "Pittsburgh Pirates",
      stadium: "PNC Park",
      stadiumID: 22,
      address: "115 Federal Street, Pittsburgh, PA 15212",
      phone: "(412) 323-5000",
      website: "pirates.com",
      orientation: 90,
      teamId: 134,
      abbreviation: "PIT",
      briefName: "Pirates",
    },
    SD: {
      team: "San Diego Padres",
      stadium: "Petco Park",
      stadiumID: 23,
      address: "100 Park Boulevard, San Diego, CA 92101",
      phone: "(619) 795-5000",
      website: "padres.com",
      orientation: 90,
      teamId: 135,
      abbreviation: "SD",
      briefName: "Padres",
    },
    SEA: {
      team: "Seattle Mariners",
      stadium: "T-Mobile Park",
      stadiumID: 25,
      address: "P.O. Box 4100, Seattle, WA 98104",
      phone: "(206) 346-4000",
      website: "mariners.com",
      orientation: 75,
      teamId: 136,
      abbreviation: "SEA",
      briefName: "Mariners",
    },
    SF: {
      team: "San Francisco Giants",
      stadium: "Oracle Park",
      stadiumID: 24,
      address: "24 Willie Mays Plaza, San Francisco, CA 94107",
      phone: "(415) 972-2000",
      website: "sfgiants.com",
      orientation: 50,
      teamId: 137,
      abbreviation: "SF",
      briefName: "Giants",
    },
    STL: {
      team: "St. Louis Cardinals",
      stadium: "Busch Stadium",
      stadiumID: 26,
      address: "700 Clark Street, St. Louis, MO 63102",
      phone: "(314) 345-9600",
      website: "cardinals.com",
      orientation: 105,
      teamId: 138,
      abbreviation: "STL",
      briefName: "Cardinals",
    },
    TB: {
      team: "Tampa Bay Rays",
      stadium: "Tropicana Field",
      stadiumID: 27,
      address: "One Tropicana Drive, St. Petersburg, FL 33705",
      phone: "(727) 825-3137",
      website: "raysbaseball.com",
      orientation: 45,
      teamId: 139,
      abbreviation: "TB",
      briefName: "Rays",
    },
    TEX: {
      team: "Texas Rangers",
      stadium: "Globe Life Field",
      stadiumID: 28,
      address: "734 Stadium Drive, Arlington, TX 76011",
      phone: "(817) 533-1972",
      website: "texasrangers.com",
      orientation: 40,
      teamId: 140,
      abbreviation: "TEX",
      briefName: "Rangers",
    },
    TOR: {
      team: "Toronto Blue Jays",
      stadium: "Rogers Centre",
      stadiumID: 29,
      address: "1 Blue Jays Way, Suite 3200, Toronto, Ontario, Canada M5V1J1",
      phone: "(416) 341-1000",
      website: "bluejays.com",
      orientation: 60,
      teamId: 141,
      abbreviation: "TOR",
      briefName: "Blue Jays",
    },
    MIN: {
      team: "Minnesota Twins",
      stadium: "Target Field",
      stadiumID: 17,
      address: "1 Twins Way, Minneapolis, MN 55403",
      phone: "(612) 659-3400",
      website: "twins.com",
      orientation: 40,
      teamId: 142,
      abbreviation: "MIN",
      briefName: "Twins",
    },
    PHI: {
      team: "Philadelphia Phillies",
      stadium: "Citizens Bank Park",
      stadiumID: 21,
      address: "One Citizens Bank Way, Philadelphia, PA 19148",
      phone: "(215) 463-6000",
      website: "phillies.com",
      orientation: 80,
      teamId: 143,
      abbreviation: "PHI",
      briefName: "Phillies",
    },
    ATL: {
      team: "Atlanta Braves",
      stadium: "Truist Park",
      stadiumID: 2,
      address: "755 Battery Avenue, Atlanta, GA 30339",
      phone: "(404) 614-2300",
      website: "braves.com",
      orientation: 80,
      teamId: 144,
      abbreviation: "ATL",
      briefName: "Braves",
    },
    CWS: {
      team: "Chicago White Sox",
      stadium: "Guaranteed Rate Field",
      stadiumID: 6,
      address: "333 West 35th Street, Chicago, IL 60616",
      phone: "(312) 674-1000",
      website: "whitesox.com",
      orientation: 145,
      teamId: 145,
      abbreviation: "CWS",
      briefName: "White Sox",
    },
    MIA: {
      team: "Miami Marlins",
      stadium: "loanDepot park",
      stadiumID: 15,
      address: "501 Marlins Way, Miami, FL 33125",
      phone: "(305) 480-1300",
      website: "marlins.com",
      orientation: 80,
      teamId: 146,
      abbreviation: "MIA",
      briefName: "Marlins",
    },
    NYY: {
      team: "New York Yankees",
      stadium: "Yankee Stadium",
      stadiumID: 19,
      address: "One East 161st Street, Bronx, NY 10451",
      phone: "(718) 293-4300",
      website: "yankees.com",
      orientation: 90,
      teamId: 147,
      abbreviation: "NYY",
      briefName: "Yankees",
    },
    MIL: {
      team: "Milwaukee Brewers",
      stadium: "American Family Field",
      stadiumID: 16,
      address: "One Brewers Way, Milwaukee, WI 53214",
      phone: "(414) 902-4400",
      website: "brewers.com",
      orientation: 90,
      teamId: 158,
      abbreviation: "MIL",
      briefName: "Brewers",
    },
  },
  // MLS: {
  //   ATL: {
  //     team: "Atlanta United FC",
  //     stadium: "Mercedes-Benz Stadium",
  //     stadiumID: 101,
  //     address: "1 AMB Drive Northwest, Atlanta, GA 30313",
  //     phone: "(470) 341-5000",
  //     website: "atlutd.com",
  //     orientation: 0,
  //   },
  //   ATX: {
  //     team: "Austin FC",
  //     stadium: "Q2 Stadium",
  //     stadiumID: 102,
  //     address: "10414 McKalla Place, Austin, TX 78758",
  //     phone: "(512) 572-8600",
  //     website: "austinfc.com",
  //     orientation: 0,
  //   },
  // },
};
