// src/data/stadiumShading/GreatAmericanBallPark.js

export const GreatAmericanBallPark = {
  name: "Great American Ball Park",
  orientation: "southeast",
  shadeData: {
    morning: {
      fullSun: [
        "101",
        "102",
        "103",
        "104",
        "105",
        "106",
        "107",
        "108",
        "109",
        "110",
        "111",
        "112",
        "113",
        "114",
        "115",
        "116",
        "117",
        "118",
        "119",
        "120",
        "121",
        "122",
        "509",
        "510",
        "511",
        "512",
        "513",
        "514",
        "515",
        "516",
        "517",
        "518",
        "519",
        "220",
        "221",
        "222",
        "223",
        "224",
        "225",
        "226",
        "227",
        "228",
      ],
      partialShade: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "22",
        "23",
        "24",
        "25",
        "140",
        "141",
        "142",
        "143",
        "144",
        "145",
        "146",
      ],
      fullShade: [
        "123",
        "124",
        "125",
        "126",
        "127",
        "128",
        "129",
        "130",
        "131",
        "132",
        "133",
        "134",
        "135",
        "136",
        "137",
        "138",
        "139",
        "301",
        "302",
        "303",
        "304",
        "305",
        "306",
        "307",
        "401",
        "402",
        "403",
        "404",
        "405",
        "406",
        "411",
        "412",
        "413",
        "414",
        "415",
        "416",
        "417",
        "418",
        "419",
        "420",
        "421",
        "422",
        "423",
        "424",
        "425",
        "426",
        "427",
        "428",
        "429",
        "430",
        "431",
        "432",
        "433",
        "434",
        "435",
        "436",
        "437",
        "SRO",
        "handlebar_sro",
        "handlebar_rail",
        "press_club",
        "district",
        "520",
        "521",
        "522",
        "523",
        "524",
        "525",
        "526",
        "527",
        "528",
        "529",
        "530",
        "531",
        "532",
        "533",
        "534",
        "535",
        "536",
        "537",
      ],
    },
    earlyAfternoon: {
      fullSun: [
        "101",
        "102",
        "103",
        "104",
        "105",
        "106",
        "107",
        "108",
        "109",
        "110",
        "111",
        "112",
        "113",
        "114",
        "115",
        "116",
        "117",
        "118",
        "119",
        "120",
        "121",
        "122",
        "509",
        "510",
        "511",
        "512",
        "513",
        "514",
        "515",
        "516",
        "517",
        "518",
        "519",
      ],
      partialShade: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "22",
        "23",
        "24",
        "25",
        "220",
        "221",
        "222",
        "223",
        "224",
        "225",
        "226",
        "227",
        "228",
      ],
      fullShade: [
        "123",
        "124",
        "125",
        "126",
        "127",
        "128",
        "129",
        "130",
        "131",
        "132",
        "133",
        "134",
        "135",
        "136",
        "137",
        "138",
        "139",
        "140",
        "141",
        "142",
        "143",
        "144",
        "145",
        "146",
        "301",
        "302",
        "303",
        "304",
        "305",
        "306",
        "307",
        "401",
        "402",
        "403",
        "404",
        "405",
        "406",
        "411",
        "412",
        "413",
        "414",
        "415",
        "416",
        "417",
        "418",
        "419",
        "420",
        "421",
        "422",
        "423",
        "424",
        "425",
        "426",
        "427",
        "428",
        "429",
        "430",
        "431",
        "432",
        "433",
        "434",
        "435",
        "436",
        "437",
        "SRO",
        "handlebar_sro",
        "handlebar_rail",
        "press_club",
        "district",
        "520",
        "521",
        "522",
        "523",
        "524",
        "525",
        "526",
        "527",
        "528",
        "529",
        "530",
        "531",
        "532",
        "533",
        "534",
        "535",
        "536",
        "537",
      ],
    },
    lateAfternoon: {
      fullSun: [
        "101",
        "102",
        "103",
        "104",
        "105",
        "106",
        "107",
        "108",
        "109",
        "110",
        "111",
        "112",
      ],
      partialShade: [
        "113",
        "114",
        "115",
        "116",
        "117",
        "118",
        "119",
        "120",
        "121",
        "122",
        "1",
        "2",
        "3",
        "4",
        "5",
        "22",
        "23",
        "24",
        "25",
        "509",
        "510",
        "511",
        "512",
        "513",
        "514",
        "515",
        "516",
        "517",
        "518",
        "519",
      ],
      fullShade: [
        "123",
        "124",
        "125",
        "126",
        "127",
        "128",
        "129",
        "130",
        "131",
        "132",
        "133",
        "134",
        "135",
        "136",
        "137",
        "138",
        "139",
        "140",
        "141",
        "142",
        "143",
        "144",
        "145",
        "146",
        "220",
        "221",
        "222",
        "223",
        "224",
        "225",
        "226",
        "227",
        "228",
        "301",
        "302",
        "303",
        "304",
        "305",
        "306",
        "307",
        "401",
        "402",
        "403",
        "404",
        "405",
        "406",
        "411",
        "412",
        "413",
        "414",
        "415",
        "416",
        "417",
        "418",
        "419",
        "420",
        "421",
        "422",
        "423",
        "424",
        "425",
        "426",
        "427",
        "428",
        "429",
        "430",
        "431",
        "432",
        "433",
        "434",
        "435",
        "436",
        "437",
        "SRO",
        "handlebar_sro",
        "handlebar_rail",
        "press_club",
        "district",
        "520",
        "521",
        "522",
        "523",
        "524",
        "525",
        "526",
        "527",
        "528",
        "529",
        "530",
        "531",
        "532",
        "533",
        "534",
        "535",
        "536",
        "537",
      ],
    },
    evening: {
      fullSun: [],
      partialShade: [
        "101",
        "102",
        "103",
        "104",
        "105",
        "106",
        "107",
        "108",
        "109",
        "110",
        "111",
        "112",
        "509",
        "510",
        "511",
        "512",
        "513",
        "514",
        "515",
        "516",
        "517",
        "518",
        "519",
      ],
      fullShade: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "22",
        "23",
        "24",
        "25",
        "113",
        "114",
        "115",
        "116",
        "117",
        "118",
        "119",
        "120",
        "121",
        "122",
        "123",
        "124",
        "125",
        "126",
        "127",
        "128",
        "129",
        "130",
        "131",
        "132",
        "133",
        "134",
        "135",
        "136",
        "137",
        "138",
        "139",
        "140",
        "141",
        "142",
        "143",
        "144",
        "145",
        "146",
        "220",
        "221",
        "222",
        "223",
        "224",
        "225",
        "226",
        "227",
        "228",
        "301",
        "302",
        "303",
        "304",
        "305",
        "306",
        "307",
        "401",
        "402",
        "403",
        "404",
        "405",
        "406",
        "411",
        "412",
        "413",
        "414",
        "415",
        "416",
        "417",
        "418",
        "419",
        "420",
        "421",
        "422",
        "423",
        "424",
        "425",
        "426",
        "427",
        "428",
        "429",
        "430",
        "431",
        "432",
        "433",
        "434",
        "435",
        "436",
        "437",
        "SRO",
        "handlebar_sro",
        "handlebar_rail",
        "press_club",
        "district",
        "520",
        "521",
        "522",
        "523",
        "524",
        "525",
        "526",
        "527",
        "528",
        "529",
        "530",
        "531",
        "532",
        "533",
        "534",
        "535",
        "536",
        "537",
      ],
    },
  },
  getShadingForTime: function (time) {
    const hour = time.getHours();
    if (hour < 12) return this.shadeData.morning;
    if (hour < 14) return this.shadeData.earlyAfternoon;
    if (hour < 17) return this.shadeData.lateAfternoon;
    return this.shadeData.evening;
  },
};
